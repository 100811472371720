import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Seo from '../components/seo';
import Layout from '../components/layout'
import TextBanner from '../components/text-banner/text-banner';
import StatsBlock from '../components/stats-block/stats-block';
import StatsBlockTwo from '../components/stats-block/stats-block-two';
import Clientblock from '../components/client-block/client-block';
import TestimonialBlock from '../components/testimonial-block/testimonial-block';

class OurImpact extends React.Component {
    render() {
        const ourWorkTextBanner = get(this, 'props.data.ourWorkTextBanner')
        const ourWorkStatsBlockOne = get(this, 'props.data.ourWorkStatsBlockOne')
        const ourWorkStatsBlockTwo = get(this, 'props.data.ourWorkStatsBlockTwo')
        const ourWorkMulticolumnHeading = get(this, 'props.data.ourWorkMulticolumnHeading')
        const ourWorkClientBlock = get(this, 'props.data.ourWorkClientBlock')
        const allContentfulTestimonialBlock = get(this, 'props.data.allContentfulTestimonialBlock')

        return (
            <Layout location={this.props.location}>
                <Seo title="Our Impact"/>
                    <div className="container-fluid container-fluid--page">
                        <TextBanner textOne={ourWorkTextBanner.textOne} textTwo={ourWorkTextBanner.textTwo} />
                        <StatsBlock stats={ourWorkStatsBlockOne.nodes} />
                        <StatsBlockTwo statsTwo={ourWorkStatsBlockTwo.nodes} />
                        <Clientblock heading={ourWorkMulticolumnHeading} clients={ourWorkClientBlock.nodes} />
                        <TestimonialBlock testimonials={allContentfulTestimonialBlock.nodes} />
                    </div>
            </Layout>
        )
    }
}

export default OurImpact;

export const query = graphql`
query ourWorkQuery {
    ourWorkTextBanner: contentfulTextBanner(textBannerName: {eq: "ourWorkTextBanner"}) {
        textBannerName
        textOne
        textTwo
    }
    ourWorkStatsBlockOne: allContentfulStatsBlockOne {
        nodes {
            statOne
            statIconOne {
                url
            }
            subStatOne
            statTwo
            statIconTwo {
                url
            }
            subStatTwo
        }
    }
    ourWorkStatsBlockTwo: allContentfulStatsBlockTwo {
        nodes {
            statOne
            statIcon {
                url
            }
            subStatOne
            statText
            statButtonUrl
            statButtonText
            statButtonIcon {
                url
            }
        }
    }
    ourWorkMulticolumnHeading: contentfulMulticolumnHeading (multicolumnHeadingName: {eq: "ourWorkMulticolumnHeading"}) {
        titleText
    }
    ourWorkClientBlock: allContentfulClientBlock {
        nodes {
            image {
                url
            }
        }
    }
    allContentfulTestimonialBlock(filter: {website: {eq: "eComplete"}}) {
        nodes {
            quote
            clientName
            positionAndCompany
        }
    }
}
`
