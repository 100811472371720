import React from "react";
import { Link } from 'gatsby'
import './stats-block.scss';

export default function StatsBlockTwo({ statsTwo }) {
    if (!statsTwo) return null
    if (!Array.isArray(statsTwo)) return null
    return (
        <div className="stats-block-container two">
             {statsTwo.map((statsTwo) => {
                return (
                    <div className="stats-block">
                        <div className="stats-block__block">
                            <div className="stats-block__block-row">
                                <p className="stat">{statsTwo.statOne}</p>
                                <div className="icon-container">
                                    <img src={statsTwo.statIcon.url} className="icon-standard" width="60" height="60" alt="Stat Icon" title="Stat Icon"/>
                                </div>
                            </div>
                            <p className="subtitle">{statsTwo.subStatOne}</p>
                        </div>
                        <div className="stats-block__block last">
                            <div className="stats-block__block-last">
                                <p className="stat-last-text">{statsTwo.statText}</p>
                                <Link to={statsTwo.statButtonUrl} class="button-secondary button-secondary--dark">
                                    {statsTwo.statButtonText}
                                    <img src={statsTwo.statButtonIcon.url} width="35" height="21" alt="Button Icon" title="Button Icon"/>
                                </Link>
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}
